/**
 * ----------------------------------------
 * Colors
 * ----------------------------------------
 */

// black
$black: #181829;

// blue
$blue: #0057b7;
$light-blue: #68afe8;
$dark-blue: #13384d;
$grey-blue: #718d9a;

// gray
$gray: #e5e5e5;

// green
$green: #20ca47;

// red
$red: #eb5757;

// white
$white: #ffffff;

// orange
$orange: #ff8b4a;

// theme colors
$background: #f7f7fc;
$auth-background: #0d1031;
$primary: #002f4d;
$primary-transparent: #0808750f;
$btn-blue: #1f96ff;
$line: #eaeaea;
$low-opacity-text: #8f8f96;
$lower-opacity-text: #c5c5ce77;
$tooltip-bg: #555;
$login-bg: #0d1031;

// Poppy theme colors
$poppy-primary: #ff8b4a;
$poppy-primary-transparent: #ff8b4a0f;
$poppy-background: #f7f7fc;
$poppy-line: #718d9a;
$poppy-low-opacity-text: #8f8f96;
$poppy-lower-opacity-text: #c5c5ce77;
$poppy-tooltip-bg: #555;
$poppy-login-bg: #0d1031;
$poppy-highlight: #d4d4d4;

$poppy-green: #7acb9c;
$poppy-green-transparent: #f4fff6;
$poppy-dark-blue: #13384d;
$poppy-blue: #6ed3e0;
$poppy-blue-transparent: #6ed3e0aa;
$poppy-red: #eb5757;
$poppy-red-transparent: #eb5757;
$poppy-orange: #f6b638;
$poppy-orange-transparent: #fff8ea;

// temperature colors
$very-cold: #0245ff;
$cold: #05abc3;
$cool: #a2d168;
$warm: #ffdf00;
$hot: #fc7908;
$very-hot: #f40701;

// test status colors
$success: #66d666;
$fail: #f8e473;

/**
 * ----------------------------------------
 * Fonts
 * ----------------------------------------
 */

$dm-sans: "DM Sans";
$sans-serif: "sans-serif";
$alata: "Alata";

/**
 * ----------------------------------------
 * Breakpoints
 * ----------------------------------------Â
 */

$phone-min: 0;
$phone-max: 599;

$tablet-min: 600;
$tablet-max: 1024;

$laptop-min: 1025;
$laptop-max: 1339;

$desktop-min: 1340;
